import request from '@/utils/request'

const type = {
    installtask: 1,
    repairtask: 2,
    maintaintask: 3,
}
const time_type = ['calibrations','tasks','recordwarnings','diarys'] //需要修改结束时间

export const searchData = (url, data) => {
    let flag = type[url]
    if (type[url]) {
        url = 'tasks'
    }
    if(url=="products"&&data.reagent_name!==undefined){
        url = 'product_reagent'
      }
    if(url=="cycle_qc_data_list"){
        url = 'qc_point_list'
    }
    if(url=="diarys"){ //日志类型转成后端需要的版本
        data['is_type'+data.diary_type]=true;
    }

    // if(time_type.includes(url)&&data.end_time){  //非样本检测，有结束时间的日期推后一天。
    //     let days = new Date(data.end_time);	
    //     days.setDate(days.getDate()+ 1);
    //     data.end_time = days.toLocaleDateString().split('/').map(e=>{
    //         return Number(e)<=9? ('0'+ e) : e
    //       }).join('-')
    // }
    // data = {...data,not_limit:1}
    return request({
        url: `/api/${url}/`,
        method: 'get',
        params: flag ? { ...data, task_type: flag } : data
    })
}
// 省份，市区
export const getCity2 = () => {
    return request({
        url: `/api/city/`,
        method: 'get',
    })
    
}
export const getProvince = () => {
    let arr = []
    request({
        url: `/api/city/`,
        method: 'get',
    }).then(res => {
        if (res.code == 200) {
            res.data.forEach(v => {
                let arr2 = []
                v.city_list.forEach(e => {
                    arr2.push({
                        value: e.city_id,
                        label: e.city_name,
                    })
                })
                arr.push({
                    value: v.province_id,
                    label: v.province_name,
                    city_list: arr2
                })
            })
        }
    })
    return arr
}

// 所有经销商
export const getCustomer = () => {
    let arr = []
    request({
        url: `/api/customer/`,
        method: 'get',
    }).then(res => {
        res.data.data.forEach(v => {
            arr.push({
                value: v.customer_id,
                label: v.customer_name
            })
        })
    })
    return arr
}
// 所有平台
export const getPlatform = (mode) => {
    let arr = []
    request({
        url: `/api/platform/`,
        method: 'get',
    }).then(res => {
        res.data.data.forEach(v => {
            if(mode == 'kits'){
                let platform_delete = process.env.VUE_APP_platform_delete.split(',')
                platform_delete.filter(e => {return e == v.platform_unique }).length == 0 && arr.push({
                    value: v.platform_unique,
                    label: v.platform_unique
                })
            }else{
                arr.push({
                    value: v.platform,
                    label: v.platform_unique
                })
            }
        })
    })
    return arr
}
// // 检测项目
// export const testing = () => {
//     return request({
//         url: `/api/testing/`,
//         method: 'get',
//     })
// }
// 检测项目
export const testing = () => {
    return request({
        url: `/api/dimension_testing/`,
        method: 'get',
    })
}
// 获取仪器日志
export const recode_log = (params) => {
    return request({
        url: `/api/recode_log/`,
        method: 'get',
        params: params,
    })
}

// 样本类型
export const getSampletype = () => {
    let arr = []
    request({
        url: `/api/sample_type/`,
        method: 'get',
    }).then(res => {
        res.data.data.forEach(v => {
            arr.push({
                value: v.sample_type_id,
                label: v.sample_type_name
            })
        })
    })
    return arr
}
// 快递接口
export const getshippingtype = (params) => {
    return request({
        url: `/api/shipping_type/`,
        method: 'get',
        params: params,
    })
}

// 获取所有的告警代码
export const dimension_warning = (params) => {
    let arr = []
    request({
        url: `/api/dimension_warning/`,
        method: 'get',
        params: params,
    }).then(res => {
        res.data.forEach(v => {
            arr.push({
                value: v.warning_id,
                label: v.warning_id
            })
        })
    })
    return arr
}

// 获取试剂对应的批号
export const reagent_list = (params) => {
    let arr = []
    request({
        url: `/api/reagent_batch/`,
        method: 'get',
        params: params,
    }).then(res => {
        res.data.forEach((v, index) => {
            let arr2 = []
            v.batch_list.length > 0 ? v.batch_list.forEach(e => {
                arr2.push({
                    value: e,
                    label: e,
                })
            }) : ''
            arr.push({
                value: index,
                label: v.reagent_name,
                report_type: v.report_type,
                reagent_class_id: v.reagent_class_id,
                children: arr2
            })
        })
    })
    return arr
}

// 医院名称模糊匹配查询
export const hospitals_list = (params) => {
    let arr = []
    request({
        url: `/api/hospitals_list/`,
        method: 'get',
        params: params,
    }).then(res => {
        if (res.code == 200) {
            let data = res.data;
            data.forEach((e, index) => {
                arr.push({
                    value: e.hospital_id,
                    label: e.hospital_full_name,
                    contact_user: e.contact_user,
                    contact_phone: e.contact_phone,

                })
            })
        }
    })
    return arr
}
// 机构名称模糊匹配查询
export const organization_list = (params) => {
    let arr = []
    request({
        url: `/api/search_organization/`,
        method: 'get',
        params: params,
    }).then(res => {
        if (res.code == 200) {
            let data = res.data;
            data.forEach((e, index) => {
                arr.push({
                    value: e.organization_id,
                    label: e.organization_name,
                })
            })
        }
    })
    return arr
}

// 医院名称模糊匹配查询传名称
export const hospitals_name_list = (params) => {
    let arr = []
    request({
        url: `/api/hospitals_list/`,
        method: 'get',
        params: params,
    }).then(res => {
        if (res.code == 200) {
            let data = res.data;
            data.forEach((e, index) => {
                arr.push({
                    value: e.hospital_full_name,
                    label: e.hospital_full_name,
                })
            })
        }
    })
    return arr
}

// 云质控医院名称模糊匹配查询
export const search_qc_hospital = (params) => {
    let arr = []
    request({
        url: `/api/search_qc_hospital/`,
        method: 'get',
        params: params,
    }).then(res => {
        if (res.code == 200) {
            let data = res.data;
            data.forEach((e, index) => {
                arr.push({
                    value: e.hospital_id,
                    label: e.hospital_name,
                })
            })
        }
    })
    return arr
}

// 产品名称模糊匹配查询
export const product_reagent = (params) => {
    return request({
        url: `/api/product_reagent/`,
        method: 'get',
        params: params,
    })
     
}

// 质检单上传获取对应信息
export const find_reagent2report = (params) => {
    return request({
        url: `/api/find_reagent2report/`,
        method: 'get',
        params: params,
    })
     
}


// 经销商模糊匹配查询
export const search_customer = (params) => {
    let arr = []
    request({
        url: `/api/search_customer/`, 
        method: 'get',
        params: params,
    }).then(res => {
        if (res.code == 200) {
            let data = res.data;
            data.forEach((e, index) => {
                arr.push({
                    value: e.customer_id,
                    label: e.customer_name,
                    contact_user: e.contact_user,
                    contact_phone: e.contact_phone,
                })
            })
        }
    })
    return arr
}

// 导出仪器的EXCLE
export const export_instrument = (params) => {
    return request({
        url: `/api/export_instrument/`,
        method: 'get',
        params: params,
    })
}

// 导出日志的EXCLE
export const export_diary = (params) => {
    return request({
        url: `/api/export_diary/`,
        method: 'get',
        params: params,
    })
}

// 导出样本检测的EXCLE
export const export_sample_check = (params) => {
    return request({
        url: `/api/export_sample_check/`,
        method: 'get',
        params: params,
    })
}

// 导出质控表格的EXCLE
export const export_qc = (params) => {
    return request({
        url: `/api/export_qc/`,
        method: 'get',
        params: params,
    })
}

// 导出告警表格的EXCLE
export const export_record_warning = (params) => {
    return request({
        url: `/api/export_record_warning/`,
        method: 'get',
        params: params,
    })
}

// 导出装维保表格的EXCLE
export const export_task = (params) => {
    return request({
        url: `/api/export_task/`,
        method: 'get',
        params: params,
    })
}
